<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex items-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="rounded-lg mr-2 my-auto"
            @click="$router.go(-1)"
          >
            <b-icon-chevron-left />
          </b-button>
          <h4><strong class="text-black text-2xl">Daftar Kontak</strong></h4>
        </b-col>
      </b-row>
      <b-row class="justify-content-between mb-[24px]">
        <BCol
          class="mb-[5px]"
          md="3"
        >
          <BInputGroup class="input-group-merge">
            <BInputGroupPrepend is-text>
              <feather-icon icon="SearchIcon" />
            </BInputGroupPrepend>
            <BFormInput
              v-model="search"
              size="md"
              placeholder="Cari berdasarkan Nama/No HP"
              style="padding: 8px 1rem 8px 0px"
              @input="searchData"
            />
          </BInputGroup>
        </BCol>
        <BCol
          class="mb-[5px] text-center"
          md="auto"
        >
          <b-button
            variant="primary"
            class="rounded-lg w-100"
            @click="addKontak"
          >
            <b-icon-plus />
            Tambah Kontak
          </b-button>
        </BCol>
      </b-row>
      <BOverlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div style="height: calc(100vh - 310px); overflow-y: scroll;" id="infinite-list">
          <BTable
            id="table-pelanggan"
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            class="mt-1"
          >

            <template #cell(action)="data">
              <div class="d-flex align-items-center gap-[15px]">
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  v-b-toggle.sidebar-backdrop
                  @click="editContact(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="deleteKontak(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
              </div>
            </template>

          </BTable>
        </div>
      </BOverlay>
      <BRow class="mt-1">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="d-flex justify-content-center align-items-center gap-[16px]">
            <strong class="text-[#333]"> Total Daftar Kontak </strong>
            <strong class="text-[#F95031]">{{ items.length || 0 }}</strong>
          </div>
        </BCol>
      </BRow>
    </BCard>
    <ModalDeleteKontak
      :id="dataDeleteKontak"
      :get-all-contact="getAllContact"
    />
    <ModalEditContact
      :data="dataEditContact"
      :get-all-contact="getAllContact"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalDeleteKontak from './modal/ModalDeleteContact.vue'
import ModalEditContact from './modal/ModalEditContact.vue'

export default {
  components: {
    ModalDeleteKontak,
    ModalEditContact,
  },
  data() {
    return {
      search: '',
      loading: false,
      isLoading: false,
      dataDeleteKontak: 0,
      dataEditContact: {},
      fields: [
        {
          key: 'name',
          label: 'Nama',
          thClass: 'font-bold text-black',
          class: 'align-middle text-black',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'No. HP',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'address',
          label: 'Lokasi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'action',
          label: 'Aksi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
      ],
      items: [],
      cursor: 0,
      limit: 10,
      lastData: false,
    }
  },
  mounted() {
    this.getAllContact()
    this.beforeDestroy()
    const listElm = document.getElementById('infinite-list')
    listElm.addEventListener('scroll', e => {
      if (
        Math.ceil(listElm.scrollTop + listElm.clientHeight)
          >= listElm.scrollHeight
        && !this.loading && !this.lastData
      ) {
        this.nextData()
      }
    })
  },
  methods: {
    searchData: _.debounce(function search() {
      this.getAllContact()
    }, 1000),
    async getAllContact() {
      this.isLoading = true
      const params = {
        search: this.search,
        limit: this.limit,
        cursor: 0,
      }
      const idParams = this.$route.params.grup_id
      await this.$http_new.get(`/komchat-api/api/v1/contact/${idParams}/list`, { params })
        .then(res => {
          const { data, meta } = res.data
          this.items = data
          this.isLoading = false
          this.cursor = meta.pagination.next_cursor
          if (data.length < this.limit || this.cursor === 0) this.lastData = true
          else this.lastData = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    async nextData() {
      this.isLoading = true
      const params = {
        search: this.search,
        limit: this.limit,
        cursor: this.cursor,
      }
      const idParams = this.$route.params.grup_id
      await this.$http_new.get(`/komchat-api/api/v1/contact/${idParams}/list`, { params })
        .then(res => {
          const { data, meta } = res.data
          this.items.push(...data)
          this.isLoading = false
          this.cursor = meta.pagination.next_cursor
          if (data.length < this.limit || this.cursor === 0) this.lastData = true
          else this.lastData = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    addKontak() {
      const id = this.$route.params.grup_id
      const datas = this.items

      localStorage.setItem('contactData', JSON.stringify(datas))
      this.$router.push(`/grup-contact/${id}/create-contact`)
    },
    beforeDestroy() {
      localStorage.removeItem('contactData')
    },
    deleteKontak(data) {
      this.dataDeleteKontak = data.id
      this.$bvModal.show('modal-delete-kontak')
    },
    editContact(data) {
      this.dataEditContact = data
    },
  },
}
</script>

  <style scoped>
  ul li {
      width: max-content !important;
  }
  </style>
